import axios from 'axios';

const StatisticModule = {
    state: () => ({
        clickedContactCompanies: [],
    }),
    mutations: {
      ADD_TO_CONTACT_CLICKED(state, company_id) {
        state.clickedContactCompanies.push(company_id)
      },
      REMOVE_FROM_CONTACT_CLICKED(state, company_id) {
        state.clickedContactCompanies = state.clickedContactCompanies.filter(item_company_id => item_company_id !== company_id);
      }
    },
    actions: {
      saveStats({state, getters}, {company_id, payment_type, fee_proposal_id}) { 
        const city_id = getters.city_for_statistic;
        const fee_proposal_ids = fee_proposal_id ? [fee_proposal_id] : undefined;
        axios.put('/api/payment/payment_statistic/save', {payment_type, company_id, city_id, fee_proposal_ids}).then(res => {
          console.log(res);
        }).catch(err => {
          console.error("saveStats: ", err, company_id);
        })
      },
    },
}
  
export default StatisticModule